@import 'variables';

/*** Text-Colors ***/
.white-text {
    color: $white;
}

.black-text {
    color: $black;
}

.grey-1-text {
    color: $grey-1;
}

.grey-2-text {
    color: $grey-2;
}

.grey-3-text {
    color: $grey-3;
}

.green-1-text {
    color: $green-1;
}

.green-2-text {
    color: $green-2;
}

.yellow-1-text {
    color: $yellow-1;
}

.yellow-2-text {
    color: $yellow-2;
}

.orange-1-text {
    color: $orange-1;
}

.orange-2-text {
    color: $orange-2;
}

.red-1-text {
    color: $red-1;
}

.red-2-text {
    color: $red-2;
}

.google-red-1-text {
    color: $google-red-1;
}

.google-red-2-text {
    color: $google-red-2;
}

.facebook-blue-1-text {
    color: $facebook-blue-1;
}

.facebook-blue-2-text {
    color: $facebook-blue-2;
}

.primary-color-1-text {
    color: $primary-color-1;
}

.primary-color-2-text {
    color: $primary-color-2;
}

.primary-color-3-text {
    color: $primary-color-3;
}

.primary-color-4-text {
    color: $primary-color-4;
}

.primary-color-5-text {
    color: $primary-color-5;
}



/*** Background-Colors ***/
.white-background {
    border-color: $white;
    background-color: $white;
}

.black-background {
    border-color: $black;
    background-color: $black;
}

.grey-1-background {
    border-color: $grey-1;
    background-color: $grey-1;
}

.grey-2-background {
    border-color: $grey-2;
    background-color: $grey-2;
}

.grey-3-background {
    border-color: $grey-3;
    background-color: $grey-3;
}

.green-1-background {
    border-color: $green-1;
    background-color: $green-1;
}

.green-2-background {
    border-color: $green-2;
    background-color: $green-2;
}

.yellow-1-background {
    border-color: $yellow-1;
    background-color: $yellow-1;
}

.yellow-2-background {
    border-color: $yellow-2;
    background-color: $yellow-2;
}

.orange-1-background {
    border-color: $orange-1;
    background-color: $orange-1;
}

.orange-2-background {
    border-color: $orange-2;
    background-color: $orange-2;
}

.red-1-background {
    border-color: $red-1;
    background-color: $red-1;
}

.red-2-background {
    border-color: $red-2;
    background-color: $red-2;
}

.google-red-1-background {
    border-color: $google-red-1;
    background-color: $google-red-1;
}

.google-red-2-background {
    border-color: $google-red-2;
    background-color: $google-red-2;
}

.facebook-blue-1-background {
    border-color: $facebook-blue-1;
    background-color: $facebook-blue-1;
}

.facebook-blue-2-background {
    border-color: $facebook-blue-2;
    background-color: $facebook-blue-2;
}

.primary-color-1-background {
	border-color: $primary-color-1;
	background-color: $primary-color-1;
}

.primary-color-2-background {
	border-color: $primary-color-2;
	background-color: $primary-color-2;
}

.primary-color-3-background {
	border-color: $primary-color-3;
	background-color: $primary-color-3;
}

.primary-color-4-background {
	border-color: $primary-color-4;
	background-color: $primary-color-4;
}

.primary-color-5-background {
	border-color: $primary-color-5;
	background-color: $primary-color-5;
}
