@import 'variables';

.tnp-submit {
	color: $button-text-color !important;
	font-weight: $button-font-weight !important;
	text-transform: $button-text-transform !important;
	text-decoration: $button-text-decoration !important;
	text-shadow: none !important;

	background-image: none !important;

	outline: 0 !important;
	border-width: $button-border-width !important;
	border-radius: $button-border-radius !important;
	border-style: solid !important;

	appearance: none !important;
	box-shadow: none !important;
	transition: none !important;
}

body.no-touch .tnp-submit {
	transition: $transition-time !important;
}

.tnp-submit,
.ginger_container .ginger_btn {
	color: $button-default-text-color !important;
	border-color: $button-default-border-color !important;
	background-color: $button-default-background-color !important;
}

body.no-touch .tnp-submit:hover,
.tnp-submit.active, .tnp-submit:active,
body.no-touch .ginger_container .ginger_btn:hover,
.ginger_container .ginger_btn.active, .ginger_container .ginger_btn:active {
	color: $button-default-text-color-active !important;
	border-color: $button-default-border-color-active !important;
	background-color: $button-default-background-color-active !important;
}

.ginger_container .ginger_btn.disabled, .ginger_container .ginger_btn[disabled],
.tnp-submit.disabled, .tnp-submit[disabled] {
	color: $button-default-text-color !important;
	border-color: $button-default-border-color !important;
	background-color: $button-default-background-color !important;
}
