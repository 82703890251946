@import 'variables';

.ginger_container {
	background-color: $cookie-notice-background-color !important;
	box-shadow: $cookie-notice-shadow !important;
}

p.ginger_message {
	color: $cookie-notice-font-color !important;
	font-size: 13px !important;
	line-height: 1.0 !important;
	margin-bottom: 5px !important;
}

p.ginger_message a {
	color: $cookie-notice-link-color !important;
	text-decoration: $cookie-notice-link-text-decoration !important;
}

p.ginger_message a:hover {
	color: $cookie-notice-link-color-active !important;
	text-decoration: $cookie-notice-link-text-decoration-active !important;
}

.ginger_container .ginger_btn {
	width: auto !important;
	max-width: none !important;
	letter-spacing: 0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}



@media screen and (max-width: $md) {

	p.ginger_message {
		font-size: 12px !important;
	}

	.ginger_container .ginger_btn {
		margin: 0 !important;
		margin-bottom: 10px !important;
		float: none !important;
	}

}
