@import 'variables';

.calendar-legend {

	margin-bottom: 5px;

	.border-box {

		.legend-item {
			display: flex;
			flex-direction: row;
			height: 100%;

			.item-color {
				width: 25px;
				height: 25px;
				margin-right: 10px;

				&.color-available {
					background-color: $primary-color-1;
				}

				&.color-limited-available {
					background-color: $primary-color-2;
				}

				&.color-unavailable {
					background-color: $primary-color-5;
					outline: $primary-color-4 1px solid;
				}
			}

			.item-text {
				font-size: $font-size-small;
				margin-top: auto;
				margin-bottom: auto;
				width: auto;
			}

		}

	}

}

@media only screen and (max-width: $md) {
	.calendar-legend {

		.border-box {
			height: auto;

			.legend-item {
				flex-direction: column;
				justify-content: center;

				.item-color {
					margin: auto;
					width: 15px;
					height: 15px;
				}

				.item-text {
					margin-top: 5px;
					font-size: $font-size-very-small-mobile;
				}

			}
		}
	}
}

