.order-book-box {

	background-color: $ls-green;

	.ls-text-box {
		h1, p {
			color: $white;
		}

		a {
			//padding: 5px 10px;
			//border: 1px solid $white;
			padding: 0;
			border: 0;
			background-color: transparent;
			color: $white;
			&:hover {
				color: $ls-beige !important;
				//color: $ls-green;
				//background-color: $white;
				//border-color: $white;
			}
		}

	}

}

.order-book-example {

	background-color: $ls-beige;

	.ls-text-box {
		h1, p {
			color: $ls-green;
		}

		input[type="submit"] {
			padding: 5px 10px;
			border: 1px solid $white;
			background-color: transparent;
			color: $white;
			&:hover {
				color: $ls-green;
				border-color: $ls-green;
			}
		}
	}

}
