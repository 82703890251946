@import 'variables';

.slick-image-slide > img {
	border-radius: 0;
}

.wpsisac-slick-slider.design-1 .slick-arrow {
	top: 50% !important;
	transform: translate(0, -50%) !important;
}



/* TODO: Breakpoint xs */
@media screen and (max-width: $xs) {

	.slick-slider,
	.slick-list,
	.slick-track,
	.slick-image-slide {
		max-height: 200px !important;
	}

}
