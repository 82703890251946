@import 'variables';

.navbar.navbar-shrink {
	background-color: transparent;
	//background-size: auto 480px;
	//background-position: center;
	//background-repeat: no-repeat;

	&.shrinked {
		background-color: $ls-beige;
		//background-image: none !important;
	}
}

/*** Navbar ***/
@media screen and (max-width: $md) {
	.navbar.navbar-shrink {
		background-color: transparent;
		//background-image: none !important;
	}
}

@media screen and (max-width: $md) {

	/*** Navbar ***/
	.navbar,
	.navbar.navbar-shrink,
	.navbar.navbar-shrink.shrinked {
		min-height: $navbar-height-mobile;
		height: $navbar-height-mobile;
	}

	/*** Navbar-Nav ***/
	.nav-style.nav-horizontal ul li {
		display: block;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 5px;
	}

	.nav-style.nav-left.sm,
	.nav-style.nav-right.sm,
	.nav-style.nav-vertical.sm {
		text-align: center;
	}

	.navbar p,
	.navbar address,
	.navbar label,
	.navbar li,
	.navbar cite {
		font-size: $navbar-font-size-mobile;
	}

	.nav-style ul li {
		font-size: $navbar-nav-font-size-mobile;
	}

	// Button
	.btn-cross-nav {
		top: $navbar-padding-mobile;
	}
}
