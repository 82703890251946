@import 'variables';

.imprint-page {

	ol {
		list-style-type: decimal !important;

		> li {
			font-weight: bold;
		}

		> ol {
			list-style-type: lower-alpha !important;
			padding-left: 10px;

			> li {
				font-weight: normal;
			}

		}
	}
}

.mentoring-page {
	ol {
		padding-left: 30px;
	}
}

.article-content {
	h1, h2 {
		text-transform: uppercase;
		font-weight: bold;
	}
}

.article-module {

	.article-content {

		font-family: Lato, sans-serif;
		font-size: $h4-size;
		line-height: floor($h4-size*1.6);

		& > p {
			font-size: inherit;
			line-height: inherit;

			&:last-child {
				margin-bottom: 0;
			}
		}

		ol, ul {
			> li {
				font-size: inherit;
				line-height: floor($h4-size*1.2);
			}
		}

	}

	.article-button {
		padding: 5px 25px;
		color: $primary-color-1;
		outline: $primary-color-1 1px solid;
		transition: color .25s ease, outline .25s ease;

		&:hover {
			color: darken($primary-color-1, 10);
			outline: darken($primary-color-1, 10) 1px solid;
		}
	}
}

.article-teaser img {
	margin-bottom: 5px;
}

.article-background-color {
	max-width: 100%;
	margin-left: 0;
	margin-right: 0;
}

.article-background-color-cat:nth-child(odd),
.article-background-color:nth-child(even) {
	background-color: $primary-color-5;
}


@media only screen and (max-width: $sm) {

	.article-module {

		.article-content {

			& > p {
				font-size: $h2-size-mobile;
			}
		}
	}
}
