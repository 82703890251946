.wpcf7 {

	/* hide ajax-loader */
	.ajax-loader {
		display: none;
	}

	/* hide checkbox label */
	.wpcf7-checkbox > .wpcf7-list-item > .wpcf7-list-item-label {
		display: none;
	}

	.btn-primary {

		padding: 5px 25px;
		color: $primary-color-1 !important;
		border: 1px solid $primary-color-1 !important;
		transition: color .25s ease, border .25s ease;

		&:hover {
			color: darken($primary-color-1, 10) !important;
			border: 1px solid darken($primary-color-1, 10) !important;
		}
	}
}
