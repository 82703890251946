@import 'variables';

.logo,
.navbar.navbar-shrink .logo,
.navbar.navbar-shrink.shrinked .logo
{
	height: 40px;
}

.navbar.navbar-shrink.shrinked .logo {
	//height: $navbar-height-mobile - (2 * $navbar-padding-mobile);
}

@media screen and (max-width: $md) {

	.logo,
	.navbar.navbar-shrink .logo {
		//height: $navbar-height-mobile - (2 * $navbar-padding-mobile);
	}

}
