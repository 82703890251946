@import 'variables';

.multiple-view-slideshow {

	position: relative;

	.slider-item-shadow {
		box-shadow: 0 0 4px 0 $primary-color-4;
	}


	.multiple-view-slider {

		background-size: cover;
		background-position: center;
		height: 500px;
		overflow: auto;


		.multiple-view-card-wrapper {


			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 15px;

			.multiple-view-card {

				background-color: rgba(255, 255, 255, 0.8);
				padding: 15px;
				width: 100%;
				height: 150px;
				overflow: auto;

				.multiple-view-title {
					font-size: 1em !important
				}

			}
		}


		.transition-box {
			height: 50px;
			bottom: 48px;
			left: 0;
			pointer-events: none;
			position: absolute;
			width: 100%;

			.transition-color {
				margin-top: 20px;
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
				background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
				height: 30px;
			}
		}

		.testimonial-image {
			margin-left: auto;
			margin-right: auto;
			max-width: 70px;
			height: auto;
		}

	}

	.pagination-spacer {
		height: 40px;
	}

}



// Pagination
.swiper-pagination-bullet {
	outline: none !important;
	height: 6px;
	width: 6px;
	background-color: $primary-color-3;
	opacity: 0.8;
}

.swiper-pagination-bullet-active {
	background-color: transparent !important;
	border: $primary-color-3 1px solid;
	width: 10px !important;
	height: 10px !important;
}



@media only screen and (max-width: $md) {


	.multiple-view-slideshow {

		.multiple-view-slider {

			height: 400px;

			.multiple-view-card-wrapper {

				padding: 10px;

				.multiple-view-content {
					& > p {
						margin-bottom: 10px;
					}
				}

			}

		}

	}

	.swiper-pagination {
		display: flex;
		justify-content: center;
		align-items: center;
	}

}


@media only screen and (max-width: $sm) {


	.multiple-view-slideshow .multiple-view-slider .testimonial-image {
		max-width: 50px;
	}


}
