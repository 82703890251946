@import 'variables';

.article-meta {
	color: $grey-1;
	font-size: $font-size-small;
}

.article-teaser img {
	width: 100%;
	height: auto;
	margin-bottom: 15px;
	margin-left: auto;
	margin-right: auto;
}



/*** Aside ***/
.article-aside {
	background-color: $article-aside-background-color;
	border: $article-aside-border;
	padding: $article-aside-padding;
	border-radius: $article-aside-border-radius;
}

.article-aside h4 {
	color: $article-aside-text-color;
	margin-bottom: 10px;
}

.article-aside li {
	list-style-image: $article-aside-list-style-image;
}

.article-aside li:last-child {
	margin-bottom: 0;
}

.article-aside li a,
.article-aside li a:focus,
.article-aside li a.focus {
	color: $article-aside-link-color;
	text-decoration: $article-aside-link-text-decoration;
}

.article-aside li.current_page_item a,
.article-aside li.current_page_parent a,
.article-aside li.current-cat a,
body.no-touch .article-aside li a:hover,
.article-aside li a:active,
.article-aside li a.active,
.article-aside li a:active:hover,
.article-aside li a.active:hover,
.article-aside li a:active:focus,
.article-aside li a.active:focus,
.article-aside li a.active.focus {
	color: $article-aside-link-color-active;
	text-decoration: $article-aside-link-text-decoration-active;
}
