@import 'variables';

.card {

	.card-img-top {
		border-radius: 0;
		min-height: 245px;
	}

	.card-body {
		background-color: $ls-beige;
		min-height: 80px;

		p {
			margin: 0;
			padding: 5px 10px;
		}

		a {
			padding: 5px 10px;
			border: 1px solid $white;
			background-color: transparent;
			color: $white;

			&:hover {
				color: $ls-green;
				border-color: $ls-green;
			}
		}
	}

	.card-footer {
		border-top: 0;
		background-color: $ls-beige;
	}

	&.program {

		a.ls-plus {
			font-size: 150%;
			color: $white;
		}
	}
}
