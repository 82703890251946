@import 'variables';

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
}

.content {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 35px;
	}

	h1 {
		font-size: 28px;
	}

}

a,
a:focus,
a.focus,
.link-style {

	&.reverse {
		color: $text-color;
	}

}


body.no-touch a:hover,
body.no-touch .link-style:hover,
a:active,
a.active,
a:active:hover,
a.active:hover,
a:active:focus,
a.active:focus,
a.active.focus {

	color: $primary-color-1;

	&:hover {
		color: darken($primary-color-1, 10);
	}

	&.reverse {
		color: $primary-color-1;
	}

}


@media only screen and (max-width: $sm) {

	.content h1 {
		font-size: 24px;
	}

}
