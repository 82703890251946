@import 'variables';

body {
	background-color: $footer-background-color;
	overflow-y: scroll;
}

body.modal-open {
	overflow-y: hidden;
}

html,
body {
	min-height: 100%;
}

#wrapper {
	width: 100%;
	background-color: $main-background-color;
	position: relative;
	overflow-x: hidden;
	overflow-y: visible;
}

.line {
	background-color: $line-color;
	width: 100%;
	height: 1px;
}

.anchor {
	position: relative;
	pointer-events: none;
	top: -$anchor-offset;
}

.wrapper-header-fixed .anchor {
	top: -$navbar-height - $anchor-offset;
}

table {
	margin-bottom: $font-size;
}

table tr td {
	vertical-align: top;
	font-size: $font-size;
	color: $text-color;
	padding-right: 10px;
}

table tr td:last-child {
	padding-right: 0;
}

.table {
	display: table;
}

.table-row {
	display: table-row;
}

.table-cell {
	display: table-cell;
}



@media screen and (max-width: $sm) {

	.wrapper-header-fixed .anchor {
		top: -$navbar-height-mobile - $anchor-offset;
	}

}