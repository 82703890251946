@import 'variables';

/* Transition */
.transition {
	transition: $transition-time;
}

.transition-none {
	transition: none;
}



/* Overflow */
.overflow-visible {
    overflow: visible;
}

.overflow-x-visible {
    overflow-x: visible;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-auto {
    overflow: scroll;
}

.overflow-x-auto {
    overflow-x: scroll;
}

.overflow-y-auto {
    overflow-y: scroll;
}



/* Float */
.float-clear {
    clear: both;
}


/* Cursor */
.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

.pointer-events-visible-painted {
    pointer-events: visiblePainted;
}



/* Position */
.top-0 {
    top: 0;
}

.top-5 {
	top: 5px;
}

.top-10 {
	top: 10px;
}

.top-15 {
	top: 15px;
}

.top-20 {
	top: 20px;
}

.top-25 {
	top: 25px;
}

.top-30 {
	top: 30px;
}

.bottom-0 {
    bottom: 0;
}

.bottom-5 {
	bottom: 5px;
}

.bottom-10 {
	bottom: 10px;
}

.bottom-15 {
	bottom: 15px;
}

.bottom-20 {
	bottom: 20px;
}

.bottom-25 {
	bottom: 25px;
}

.bottom-30 {
	bottom: 30px;
}

.left-0 {
    left: 0;
}

.left-5 {
	left: 5px;
}

.left-10 {
	left: 10px;
}

.left-15 {
	left: 15px;
}

.left-20 {
	left: 20px;
}

.left-25 {
	left: 25px;
}

.left-30 {
	left: 30px;
}

.right-0 {
    right: 0;
}

.right-5 {
	right: 5px;
}

.right-10 {
	right: 10px;
}

.right-15 {
	right: 15px;
}

.right-20 {
	right: 20px;
}

.right-25 {
	right: 25px;
}

.right-30 {
	right: 30px;
}



.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.z-index-4 {
    z-index: 4;
}

.z-index-5 {
    z-index: 5;
}

.z-index-minus-1 {
    z-index: -1;
}

.z-index-minus-2 {
    z-index: -2;
}

.z-index-minus-3 {
    z-index: -3;
}

.z-index-minus-4 {
    z-index: -4;
}

.z-index-minus-5 {
    z-index: -5;
}



/* Text */
.text-overflow-elipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.text-overflow-center {
    margin-left: -100%;
    margin-right: -100%;
    text-align: center;
}

.text-transform-uppercase {
	text-transform: uppercase;
}

.text-transform-none {
	text-transform: none;
}

.text-decoration-underline {
    text-decoration: underline !important;
}



/* Width */
.width-max {
    width: 100%;
}

.width-auto {
    width: auto;
}

.width-0 {
    width: 0;
}

.width-5 {
    width: 5px;
}

.width-10 {
    width: 10px;
}

.width-15 {
    width: 15px;
}

.width-20 {
    width: 20px;
}

.width-25 {
    width: 25px;
}

.width-30 {
    width: 30px;
}

.width-35 {
    width: 35px;
}

.width-40 {
    width: 40px;
}

.width-45 {
    width: 45px;
}

.width-50 {
    width: 50px;
}

.width-60 {
    width: 60px;
}

.width-70 {
    width: 70px;
}

.width-80 {
    width: 80px;
}

.width-90 {
    width: 90px;
}

.width-100 {
    width: 100px;
}

.width-150 {
    width: 150px;
}

.width-200 {
    width: 200px;
}

.width-250 {
    width: 250px;
}

.width-auto-important {
    width: auto !important;
}



/* Height */
.height-max {
    height: 100%;
}

.height-auto {
    height: auto;
}

.height-0 {
    height: 0;
}

.height-5 {
    height: 5px;
}

.height-10 {
    height: 10px;
}

.height-15 {
    height: 15px;
}

.height-20 {
    height: 20px;
}

.height-25 {
    height: 25px;
}

.height-30 {
    height: 30px;
}

.height-35 {
    height: 35px;
}

.height-40 {
    height: 40px;
}

.height-45 {
    height: 45px;
}

.height-50 {
    height: 50px;
}

.height-60 {
    height: 60px;
}

.height-70 {
    height: 70px;
}

.height-80 {
    height: 80px;
}

.height-90 {
    height: 90px;
}

.height-100 {
    height: 100px;
}

.height-150 {
    height: 150px;
}

.height-200 {
    height: 200px;
}

.height-250 {
    height: 250px;
}



/* Min-Height */
.min-height-auto {
    min-height: auto;
}

.min-height-0 {
    min-height: 0;
}

.min-height-5 {
    min-height: 5px;
}

.min-height-10 {
    min-height: 10px;
}

.min-height-15 {
    min-height: 15px;
}

.min-height-20 {
    min-height: 20px;
}

.min-height-25 {
    min-height: 25px;
}

.min-height-30 {
    min-height: 30px;
}

.min-height-35 {
    min-height: 35px;
}

.min-height-40 {
    min-height: 40px;
}

.min-height-45 {
    min-height: 45px;
}

.min-height-50 {
    min-height: 50px;
}

.min-height-60 {
    min-height: 60px;
}

.min-height-70 {
    min-height: 70px;
}

.min-height-80 {
    min-height: 80px;
}

.min-height-90 {
    min-height: 90px;
}

.min-height-100 {
    min-height: 100px;
}

.min-height-150 {
    min-height: 150px;
}

.min-height-200 {
    min-height: 200px;
}

.min-height-250 {
    min-height: 250px;
}
