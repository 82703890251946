@import 'variables';

.form-control {
	box-shadow: $general-box-shadow !important;
	margin-bottom: 15px;
}

.checkbox-label {

	display: flex;
	flex-direction: row;
	justify-content: left;
	font-size: $font-size-small;

	.form-checkbox {

		position: relative;

		input[type="checkbox"] {
			//position: absolute;
			//top: -4px;
			//width: 20px;
			//height: 20px;
			background-color: white !important;
		}

	}

}


@media only screen and (max-width: $xs) {

	.send-button {
		width: 200px;
	}

}
