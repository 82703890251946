@import 'variables';

footer {
	background-color: $footer-background-color;
	position: relative;
	z-index: 1;
	margin-top: $footer-margin-top;
	padding-top: $footer-padding-top;
	padding-bottom: $footer-padding-bottom;
}

footer p,
footer address,
footer label,
footer li,
footer cite {
	font-size: $footer-font-size;
	color: $footer-text-color;
}

footer a,
footer a:focus,
footer a.focus,
footer .link-style {
	color: $footer-link-color;
	text-decoration: $footer-link-text-decoration;
}

body.no-touch footer a:hover,
body.no-touch footer .link-style:hover,
footer a:active,
footer a.active,
footer a:active:hover,
footer a.active:hover,
footer a:active:focus,
footer a.active:focus,
footer a.active.focus {
	color: $footer-link-color-active;
	text-decoration: $footer-link-text-decoration-active;
}

footer .nav-style li {
	font-size: $footer-nav-font-size;
}

footer .nav-style ul li a,
footer .nav-style ul li a:focus,
footer .nav-style ul li a.focus {
	color: $footer-nav-color;
	text-decoration: $footer-nav-text-decoration;
	text-transform: $footer-nav-text-transform;
}

footer .nav-style ul li.current_page_item a,
footer .nav-style ul li.current_page_parent a,
footer .nav-style ul li.current-cat a,
body.no-touch footer .nav-style ul li a:hover,
footer .nav-style ul li a:active,
footer .nav-style ul li a.active,
footer .nav-style ul li a:active:hover,
footer .nav-style ul li a.active:hover,
footer .nav-style ul li a:active:focus,
footer .nav-style ul li a.active:focus,
footer .nav-style ul li a.active.focus {
	color: $footer-nav-color-active;
	text-decoration: $footer-nav-text-decoration-active;
}



@media screen and (max-width: $sm) {

	footer p,
	footer address,
	footer label,
	footer li,
	footer cite {
		font-size: $footer-font-size-mobile;
	}

	footer .nav-style li {
		font-size: $footer-nav-font-size-mobile;
	}

}