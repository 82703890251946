@import 'variables';

main {
	background-color: $main-background-color;
}

//.wrapper-header-fixed main {
//	margin-top: $navbar-height;
//}

//.wrapper-header-shrink main {
//	margin-top: $navbar-shrink-height;
//}


///* TODO: Breakpoint sm */
//@media screen and (max-width: $sm) {
//
//	.wrapper-header-fixed main,
//	.wrapper-header-shrink main {
//		margin-top: $navbar-height-mobile;
//	}
//
//}
