@import 'variables';
.article-aside {
  .nav-style {
	.menu {
	  display: flex;
	  flex-wrap: wrap;
	  flex-direction: row;
	  justify-content: start;

	  & > li {
		display: flex;
		padding-right: 15px;

		& > a {
		  outline: $primary-color-1 1px solid;
		  color: $primary-color-3;
		  margin-top: 5px;
		  padding: 7px 5px 0 5px;
		  transition: background-color .25s ease, color .25s ease;
		  font-family: $font-family-2;
		}

		& > a:hover {
		  outline: $primary-color-2 1px solid;
		  color: $primary-color-2;
		}
	  }
	}
  }
}

@media only screen and (max-width: $xs) {

  .article-aside .nav-style .menu {
	flex-direction: column;
	padding-left: 15px;
  }

}
