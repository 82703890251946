.blog-card {
	border: 0;

	.card-body {
		margin-top: 5px;
		color: $ls-green;
		background-color: lighten($ls-beige, 10%);

		p {
			color: inherit;
		}

		a.blog-link {
			//color: $gray-900;

			&:hover {
				//color: $gray-700;
			}
		}
	}
}
