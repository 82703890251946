.ls-text-box {

	display: flex;
	min-height: 350px;
	align-items: center;

	img.ls-text-image {
		height: 350px;
	}

	h1 {
		text-transform: uppercase;
	}

	a {
		padding: 5px 10px;
		border: 1px solid $ls-light-green;
		background-color: $ls-light-green;
		color: $white;
		&:hover {
			color: $white;
		}
	}
}
