@import 'variables';

* {
    font-family: $font-family-1;
}

body {
	-webkit-font-smoothing: antialiased;
}

p,
address,
label,
li,
cite {
	font-size: $font-size;
    font-weight: $font-regular;
    color: $text-color;
    line-height: $line-height;
    margin-bottom: $font-size;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	line-height: $line-height;
	margin: 0;
}

h1,
.h1 {
    font-family: $h1-font-family;
    font-size: $h1-size;
    margin-bottom: $h1-margin-bottom;
    font-weight: $h1-font-weight;
    color: $h1-color;
    text-transform: $h1-text-transform;
}

h2,
.h2 {
    font-family: $h2-font-family;
    font-size: $h2-size;
    margin-bottom: $h2-margin-bottom;
    font-weight: $h2-font-weight;
    color: $h2-color;
    text-transform: $h2-text-transform;
}

h3,
.h3 {
    font-family: $h3-font-family;
    font-size: $h3-size;
    margin-bottom: $h3-margin-bottom;
    font-weight: $h3-font-weight;
    color: $h3-color;
    text-transform: $h3-text-transform;
}

h4,
.h4 {
    font-family: $h4-font-family;
    font-size: $h4-size;
    margin-bottom: $h4-margin-bottom;
    font-weight: $h4-font-weight;
    color: $h4-color;
    text-transform: $h4-text-transform;
}

h5,
.h5 {
    font-family: $h5-font-family;
    font-size: $h5-size;
    margin-bottom: $h5-margin-bottom;
    font-weight: $h5-font-weight;
    color: $h5-color;
    text-transform: $h5-text-transform;
}

h6,
.h6 {
    font-family: $h6-font-family;
    font-size: $h6-size;
    margin-bottom: $h6-margin-bottom;
    font-weight: $h6-font-weight;
    color: $h6-color;
    text-transform: $h6-text-transform;
}

li {
    margin-bottom: round($font-size / 2);
}

li:last-child {
    margin-bottom: $font-size;
}

ul,
ol {
    padding-left: 17px;
}

ol li,
ul li {
    padding-left: 5px;
}

ul li {
    list-style-image: $list-style-image;
}

a,
a:focus,
a.focus,
.link-style {
    color: $link-color;
	cursor: pointer;
    text-decoration: $link-text-decoration;
    transition: none;
}

body.no-touch a,
body.no-touch .link-style {
	transition: color $transition-time ease;
}

body.no-touch a:hover,
body.no-touch .link-style:hover,
a:active,
a.active,
a:active:hover,
a.active:hover,
a:active:focus,
a.active:focus,
a.active.focus {
	color: $link-color-active;
    text-decoration: $link-text-decoration-active;
}

strong {
    font-weight: $font-bold;
}

.small-text {
    font-size: $font-size-small;
}

.very-small-text {
    font-size: $font-size-very-small;
}



/* TODO: Breakpoint sm */
@media screen and (max-width: $sm) {

    h1,
    .h1 {
        font-size: $h1-size-mobile;
        margin-bottom: $h1-margin-bottom-mobile;
    }

    h2,
    .h2 {
        font-size: $h2-size-mobile;
        margin-bottom: $h2-margin-bottom-mobile;
    }

    h3,
    .h3 {
        font-size: $h3-size-mobile;
        margin-bottom: $h3-margin-bottom-mobile;
    }

    h4,
    .h4 {
        font-size: $h4-size-mobile;
        margin-bottom: $h4-margin-bottom-mobile;
    }

    h5,
    .h5 {
        font-size: $h5-size-mobile;
        margin-bottom: $h5-margin-bottom-mobile;
    }

    h6,
    .h6 {
        font-size: $h6-size-mobile;
        margin-bottom: $h6-margin-bottom-mobile;
    }

    p,
    address,
    label,
    li,
    cite {
        font-size: $font-size-mobile;
        margin-bottom: $font-size-mobile;
    }

    li {
        margin-bottom: round($font-size-mobile / 2);
    }

    li:last-child {
        margin-bottom: $font-size-mobile;
    }

    .small-text {
        font-size: $font-size-small-mobile;
    }

    .very-small-text {
        font-size: $font-size-very-small-mobile;
    }

}
