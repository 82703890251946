@import 'variables';

@-webkit-keyframes rotation {

  from {
	-webkit-transform: rotate(0deg);
  }
  to {
	-webkit-transform: rotate(359deg);
  }

}

body.no-touch .hover-up {
  transition: $transition-time;
}

body.no-touch .hover-up:hover {
  transform: translate(0, -3px);
}

.no-hover-up {
  transform: none !important;
}
