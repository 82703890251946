@import '~ki-ware-style-guide-wp/scss/variables';

/*** limarys sterne colors ***/

$ls-green: #187d6c;

$ls-light-green: #d1d877;
$ls-beige: #D1C9C0;


/*** Colors ***/
$primary-color-1: #91b02a;
$primary-color-2: $ls-light-green;
$primary-color-3: $ls-green;
$primary-color-4: #c6c6c6;
$primary-color-5: #eeeeee;
$primary-color-6: #ffffff;

/*** Fonts ***/
$font-family-1: $calibri;
$font-family-2: $calibri;

$headline-font-family: $font-family-2;
$h1-font-family: $headline-font-family;
$h2-font-family: $headline-font-family;
$h3-font-family: $headline-font-family;
$h4-font-family: $headline-font-family;
$h5-font-family: $headline-font-family;
$h6-font-family: $headline-font-family;

$headline-text-transform: none;
$h1-text-transform: $headline-text-transform;
$h2-text-transform: $headline-text-transform;
$h3-text-transform: $headline-text-transform;
$h4-text-transform: $headline-text-transform;
$h5-text-transform: $headline-text-transform;
$h6-text-transform: $headline-text-transform;

/* Font Weight */
$font-light: $font-light;
$font-regular: $font-regular;
$font-bold: $font-bold;

$headline-font-weight: $font-regular;
$h1-font-weight: $headline-font-weight;
$h2-font-weight: $headline-font-weight;
$h3-font-weight: $headline-font-weight;
$h4-font-weight: $headline-font-weight;
$h5-font-weight: $headline-font-weight;
$h6-font-weight: $headline-font-weight;

$line-height: 1.3;

/* Font Color */
$text-color: $primary-color-3;
$headline-color: $primary-color-3;

$h1-color: $headline-color;
$h2-color: $headline-color;
$h3-color: $headline-color;
$h4-color: $headline-color;
$h5-color: $headline-color;
$h6-color: $headline-color;

/* Font size */
$font-size: 16px;
$font-size-small: 12px;
$font-size-very-small: 10px;
$h1-size: 24px;
$h2-size: $h1-size;
$h3-size: $h1-size;
$h4-size: 20px;
$h5-size: 18px;
$h6-size: $font-size;

$font-size-mobile: 16px;
$font-size-small-mobile: 14px;
$font-size-very-small-mobile: 12px;
$h1-size-mobile: 22px;
$h2-size-mobile: $h1-size-mobile;
$h3-size-mobile: $h1-size-mobile;
$h4-size-mobile: 18px;
$h5-size-mobile: 16px;
$h6-size-mobile: $font-size-mobile;

/* Margin */
$h1-margin-bottom: $h1-size;
$h2-margin-bottom: $h1-margin-bottom;
$h3-margin-bottom: $h1-margin-bottom;
$h4-margin-bottom: $font-size;
$h5-margin-bottom: $font-size;
$h6-margin-bottom: $font-size;

$h1-margin-bottom-mobile: $h1-size-mobile;
$h2-margin-bottom-mobile: $h1-margin-bottom-mobile;
$h3-margin-bottom-mobile: $h1-margin-bottom-mobile;
$h4-margin-bottom-mobile: $font-size-mobile;
$h5-margin-bottom-mobile: $font-size-mobile;
$h6-margin-bottom-mobile: $font-size-mobile;

/* Links */
$link-color: $primary-color-1;
$link-color-active: $primary-color-2;
$link-text-decoration: none;
$link-text-decoration-active: none;

/* List */
//$list-style-image: url(../images/components/list_item.png);
//$list-style-image-white: url(../images/components/list_item_white.png);


/*** Buttons ***/
$button-text-color: $primary-color-1;
$button-font-weight: $button-font-weight;
$button-text-transform: $button-text-transform;
$button-text-decoration: $button-text-decoration;
$button-border-width: 1px;
$button-border-radius: 0;

$button-default-text-color: $primary-color-1;
$button-default-text-color-active: $primary-color-3;
$button-default-background-color: transparent;
$button-default-background-color-active: transparent;
$button-default-border-color: $primary-color-1;
$button-default-border-color-active: $primary-color-3;

$button-primary-text-color: $primary-color-3;
$button-primary-text-color-active: $primary-color-1;
$button-primary-background-color: transparent;
$button-primary-background-color-active: transparent;
$button-primary-border-color: $primary-color-3;
$button-primary-border-color-active: $primary-color-1;


/*** Form ***/
//$form-input-border: none;
//$form-input-border-radius: 2px;
//$form-background-color: white;
//$form-dropdown-arrow: url(../images/components/dropdown_arrow.png);


/*** Layout ***/
$line-color: $primary-color-1;
$anchor-offset: 0;


/*** Navbar ***/
$navbar-font-size: $navbar-font-size;
$navbar-font-size-mobile: $navbar-font-size-mobile;

$navbar-background-color: transparent;
$navbar-text-color: $black;
$navbar-shadow: none;

$navbar-height: 50px;
$navbar-height-mobile: $navbar-height;
$navbar-shrink-height: $navbar-height;
$navbar-padding: 25px;
$navbar-padding-mobile: 10px;

/* Navbar-Nav */
$navbar-nav-font-size: $navbar-font-size;
$navbar-nav-font-size-mobile: $navbar-font-size-mobile;

$navbar-nav-color: $white;
$navbar-nav-color-active: $ls-green;

$navbar-nav-text-decoration: none;
$navbar-nav-text-decoration-active: none;
$navbar-nav-text-transform: uppercase;

$navbar-nav-margin-top: 18px;
$navbar-shrink-nav-margin-top: 18px;
$navbar-shrink-shrinked-nav-margin-top: 18px;

/* pagination */
$pagination-active-bg: $primary-color-1;
$pagination-active-border-color: $primary-color-1;


/* Offcanvas-Nav */
$offcanvas-menu-color: $white;
$offcanvas-menu-background-color: $ls-beige;
$offcanvas-menu-color-active: $ls-green;
$offcanvas-menu-background-color-active: $ls-beige;

$offcanvas-button-size: 30px;

$offcanvas-button-color: $ls-beige;
$offcanvas-button-color-active: $ls-beige;
$offcanvas-button-line-color: $white;


/*** Main ***/
$main-background-color: $white;


/*** Footer ***/
$footer-font-size: $footer-font-size;
$footer-font-size-mobile: $footer-font-size-mobile;

$footer-background-color: $ls-beige;
$footer-text-color: $ls-green;

$footer-link-color: $ls-green;
$footer-link-color-active: $ls-green;
$footer-link-text-decoration: none;
$footer-link-text-decoration-active: underline;

$footer-margin-top: 0;
$footer-padding-top: 30px;
$footer-padding-bottom: 70px;

/* Footer-Nav */
$footer-nav-font-size: $footer-font-size;
$footer-nav-font-size-mobile: $footer-font-size-mobile;

$footer-nav-color: $ls-green;
$footer-nav-color-active: $ls-green;
$footer-nav-text-decoration: none;
$footer-nav-text-decoration-active: underline;
$footer-nav-text-transform: none;


/*** Articles ***/

/* Aside */
$article-aside-padding: $article-aside-padding;
$article-aside-border-radius: $article-aside-border-radius;
$article-aside-background-color: $article-aside-background-color;
$article-aside-border: $article-aside-border;

$article-aside-text-color: $text-color;
$article-aside-list-style-image: $list-style-image;

$article-aside-link-color: $text-color;
$article-aside-link-color-active: $primary-color-1;
$article-aside-link-text-decoration: none;
$article-aside-link-text-decoration-active: none;


/*** Elementor ***/
$elementor-gallery-padding: 0;


/*** Cookie notice ***/
$cookie-notice-font-color: $text-color;
$cookie-notice-background-color: $primary-color-5;
$cookie-notice-shadow: $cookie-notice-shadow;

$cookie-notice-link-color: $link-color;
$cookie-notice-link-color-active: $link-color-active;
$cookie-notice-link-text-decoration: $link-text-decoration;
$cookie-notice-link-text-decoration-active: $link-text-decoration-active;

$cookie-notice-button-background-color: $button-default-background-color;
$cookie-notice-button-background-color-active: $button-default-background-color-active;
$cookie-notice-button-border-color: $button-default-background-color;
$cookie-notice-button-border-color-active: $button-default-background-color-active;


/*** Custom ***/
$general-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
