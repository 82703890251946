@import 'variables';

.general-box-shadow {
	box-shadow: none;
}

.grey {
	background-color: $primary-color-5;
}

.max-width {
	width: 100%;
}

.header-image-wrapper {
	margin-bottom: 0;
}

.grecaptcha-badge {
	z-index: -1;
}

//@media screen and (max-width: $md) {
//	.wrapper-header-shrink main {
//		margin-top: $navbar-height;
//	}
//}
