@import 'variables';

/*** Navbar ***/
.navbar {
	background-color: $navbar-background-color;
	min-height: $navbar-height;
	height: $navbar-height;
	border: none;
	box-shadow: $navbar-shadow;

	flex-wrap: nowrap;

	transition: $transition-time;
}

.navbar.navbar-shrink {
	min-height: $navbar-shrink-height;
	height: $navbar-shrink-height;
}

.navbar.navbar-shrink.shrinked {
	min-height: $navbar-height-mobile;
	height: $navbar-height-mobile;
}

.navbar .nav-style {
	margin-top: $navbar-nav-margin-top;
	transition: $transition-time;
}

.navbar.navbar-shrink .nav-style {
	margin-top: $navbar-shrink-nav-margin-top;
}

.navbar.navbar-shrink.shrinked .nav-style {
	margin-top: $navbar-shrink-shrinked-nav-margin-top;
}

.navbar p,
.navbar address,
.navbar label,
.navbar li,
.navbar cite {
	font-size: $navbar-font-size;
	color: $navbar-text-color;
}

.header-bg {
	background-color: $navbar-background-color;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: $navbar-shrink-height;
	display: none;
	pointer-events: none;
}

.wrapper-header-shrink .header-bg {
	display: block;
}



/*** Navbar-Nav ***/
.nav-style ul,
.nav-style li {
	padding-left: 0;
}

.nav-style ul li {
	list-style: none none;
	margin-bottom: 0;
	position: relative;
}

.navbar .nav-style ul li {
	font-size: $navbar-nav-font-size;
}

.navbar .nav-style ul li a {
	position: relative;
	z-index: 1;
}



/* Nav horizontal */
.nav-style.nav-horizontal ul li {
	display: inline-block;
}



/* Nav vertical */
.nav-style.nav-vertical ul li {
	margin-bottom: 5px;
}

.nav-style.nav-vertical ul li:last-child {
	margin-bottom: 0;
}



/* Nav left */
.nav-style.nav-left {
	text-align: left;
}

.nav-horizontal.nav-left ul li {
	margin-right: 15px;
}



/* Nav right */
.nav-style.nav-right {
	text-align: right;
}

.nav-horizontal.nav-right ul li {
	margin-left: 15px;
}



/* Nav center */
.nav-horizontal.nav-center {
	text-align: center;
}

.nav-horizontal.nav-center ul li {
	margin-left: 8px;
	margin-right: 8px;
}



/* Sub menu */
.navbar .nav-style li > .sub-menu {
	display: none;
	position: absolute;
	top: 0;
	padding-top: 30px;
	z-index: 0;
}

.navbar .nav-style.nav-right li > .sub-menu {
	right: -15px;
	text-align: right;
}

.navbar .nav-style.nav-left li > .sub-menu {
	left: -15px;
	text-align: left;
}

.navbar .nav-style li:hover .sub-menu {
	display: block;
}

.navbar .nav-style .sub-menu li {
	display: block;
	margin: 0;
	white-space: nowrap;
	padding-top: 0;
	padding-bottom: 10px;
}

.navbar .nav-style .sub-menu > li {
	padding-left: 15px;
	padding-right: 15px;
}

.navbar .nav-style ul li a,
.navbar .nav-style ul li a:focus,
.navbar .nav-style ul li a.focus,
.navbar .nav-style .sub-menu li a,
.navbar .nav-style .sub-menu li a:focus,
.navbar .nav-style .sub-menu li a.focus {
	color: $navbar-nav-color;
	text-decoration: $navbar-nav-text-decoration;
	text-transform: $navbar-nav-text-transform;
}

.navbar .nav-style .menu > li.current_page_item > a,
.navbar .nav-style .menu > li.current_page_parent > a,
.navbar .nav-style .menu > li.current-cat > a,
body.no-touch .navbar .nav-style .menu > li > a:hover,
.navbar .nav-style .menu > li > a:active,
.navbar .nav-style .menu > li > a.active,
.navbar .nav-style .menu > li > a:active:hover,
.navbar .nav-style .menu > li > a.active:hover,
.navbar .nav-style .menu > li > a:active:focus,
.navbar .nav-style .menu > li > a.active:focus,
.navbar .nav-style .menu > li > a.active.focus,
.navbar .nav-style .sub-menu > li.current_page_item > a,
.navbar .nav-style .sub-menu > li.current_page_parent > a,
.navbar .nav-style .sub-menu > li.current-cat > a,
body.no-touch .navbar .nav-style .sub-menu > li > a:hover,
.navbar .nav-style .sub-menu > li > a:active,
.navbar .nav-style .sub-menu > li > a.active,
.navbar .nav-style .sub-menu > li > a:active:hover,
.navbar .nav-style .sub-menu > li > a.active:hover,
.navbar .nav-style .sub-menu > li > a:active:focus,
.navbar .nav-style .sub-menu > li > a.active:focus,
.navbar .nav-style .sub-menu > li > a.active.focus {
	color: $navbar-nav-color-active;
	text-decoration: $navbar-nav-text-decoration-active;
}

.navbar .nav-style .menu > li > .sub-menu {
	background-color: $navbar-background-color;
}



/* TODO: Breakpoint sm */
@media screen and (max-width: $sm) {

	/*** Navbar ***/
	.navbar,
	.navbar.navbar-shrink,
	.navbar.navbar-shrink.shrinked {
		min-height: $navbar-height-mobile;
		height: $navbar-height-mobile;
	}

	.header-bg {
		height: $navbar-height-mobile;
	}



	/*** Navbar-Nav ***/
	.nav-style.nav-horizontal ul li {
		display: block;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 5px;
	}

	.nav-style.nav-left.sm,
	.nav-style.nav-right.sm,
	.nav-style.nav-vertical.sm {
		text-align: center;
	}

	.navbar p,
	.navbar address,
	.navbar label,
	.navbar li,
	.navbar cite {
		font-size: $navbar-font-size-mobile;
	}

	.nav-style ul li {
		font-size: $navbar-nav-font-size-mobile;
	}

}
