@import 'variables';

.module-facebook-wrapper {
	.facebook-image > img {
		max-width: 150px;
	}
}

@media only screen and (max-width: $sm) {
	.module-facebook-wrapper .facebook-image > img {
		max-width: 150px;
	}
}
