@import 'variables';

.footer-wrapper {

	.footer-item {

		.footer-headline {
			text-transform: uppercase;
			color:$ls-green;
			font-weight: bold;
		}

		.footer-menu {

			text-decoration: none;

			ul {

				padding-left: 0;
				list-style: none;

				li {
					padding-left: inherit;
					list-style: inherit;
				}

			}
		}
	}
}


@media only screen and (max-width: $md) {

	.footer-wrapper {

		.footer-item {

			.footer-headline {
				font-size: 18px;
			}

		}

	}

}


@media only screen and (max-width: $sm) {

	.footer-wrapper {

		text-align: center;

		.footer-item {
			padding-top: 15px;
		}

	}

}
