@import 'variables';

//.header-image-wrapper {
//	margin-bottom: 30px;
//}

.container-fluid .header-image-wrapper {
	margin-left: -15px;
	margin-right: -15px;
}
