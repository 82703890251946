@import 'variables';

.off-canvas-left,
.off-canvas-right {
    position: fixed;
    top: 0;
    width: 85%;
    height: 100%;
    z-index: 102;
    background-color: $ls-beige;

    padding-top: $navbar-height;

    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    transition: left 0.5s ease;
}

.off-canvas-left-button {
    left: 15px;
}

.off-canvas-right-button {
    right: 15px;
}

.off-canvas-left {
    left: -90%;
}

.off-canvas-left.active {
    left: 0;
}

.off-canvas-right {
    left: 105%;
}

.off-canvas-right.active {
    left: 15%;
}

.off-canvas-close-button {
    background-color: $black !important;
    opacity: 0.0;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 101;
    cursor: pointer;

    border-radius: 0;
    transition: opacity 0.5s ease;
}

.off-canvas-close-button.active {
    opacity: 0.5;
}

body.modal-open {
	header.navbar {
	background-color:	$ls-beige;
}
}

.off-canvas-menu ul {
    padding: 0;
}

.off-canvas-menu li {
    margin: 0;
    padding: 0;
}

.off-canvas-left.off-canvas-menu li {
    text-align: right;
}

.off-canvas-menu a {
	background-color: $offcanvas-menu-background-color;
	color: $offcanvas-menu-color;
    display: block;
    margin: 0;
    padding: 15px 20px;
}

.off-canvas-menu.off-canvas-right .sub-menu a {
    padding-left: 30px;
}

.off-canvas-menu.off-canvas-left .sub-menu a {
    padding-right: 30px;
}

.off-canvas-menu li.current_page_item > a,
.off-canvas-menu li.current_page_parent > a,
.off-canvas-menu li.current-cat > a,
.off-canvas-menu a.active {
    background-color: $offcanvas-menu-background-color-active;
    color: $offcanvas-menu-color-active;
}


///* TODO: Breakpoint sm */
//@media screen and (max-width: $sm) {
//	.off-canvas-left,
//	.off-canvas-right {
//		padding-top: $navbar-height-mobile;
//	}
//}
