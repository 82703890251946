@import 'variables';

/*** Button general ***/
.btn,
.elementor-button,
.ginger_container .ginger_btn {
	color: $button-text-color !important;
	font-weight: $button-font-weight !important;
	text-transform: $button-text-transform !important;
	text-decoration: $button-text-decoration !important;
	text-shadow: none !important;

	background-image: none !important;

	outline: 0 !important;
    border-width: $button-border-width !important;
	border-radius: $button-border-radius !important;
	border-style: solid !important;

	appearance: none !important;
	box-shadow: none !important;
	transition: none !important;
}

body.no-touch .btn,
body.no-touch .elementor-button,
body.no-touch .ginger_container .ginger_btn {
	transition: $transition-time !important;
}

.btn.disabled,
.elementor-button.disabled,
.ginger_container .ginger_btn.disabled {
    opacity: 0.65;
    cursor: not-allowed;
    pointer-events: none;
}

.btn-default,
.elementor-button {
	color: $button-default-text-color !important;
	border-color: $button-default-border-color !important;
	background-color: $button-default-background-color !important;
}

body.no-touch .btn-default:hover,
.btn-default.active, .btn-default:active,
body.no-touch .elementor-button:hover,
.elementor-button.active, .elementor-button:active {
	color: $button-default-text-color-active !important;
	border-color: $button-default-border-color-active !important;
	background-color: $button-default-background-color-active !important;
}

.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default,
.elementor-button.disabled, .elementor-button[disabled], fieldset[disabled] .elementor-button {
	color: $button-default-text-color !important;
	border-color: $button-default-border-color !important;
	background-color: $button-default-background-color !important;
}

.btn-primary {
	color: $button-primary-text-color !important;
	border-color: $button-primary-border-color !important;
	background-color: $button-primary-background-color !important;
}

body.no-touch .btn-primary:hover,
.btn-primary.active, .btn-primary:active {
	color: $button-primary-text-color-active !important;
	border-color: $button-primary-border-color-active !important;
	background-color: $button-primary-background-color-active !important;
}

.btn-primary.disabled, .btn-primary[disabled], fieldset[disabled] .btn-primary {
	color: $button-primary-text-color !important;
	border-color: $button-primary-border-color !important;
	background-color: $button-primary-background-color !important;
}

.btn-success {
    border-color: $green-1 !important;
    background-color: $green-1 !important;
}

body.no-touch .btn-success:hover,
.btn-success.active, .btn-success:active {
    border-color: $green-2 !important;
    background-color: $green-2 !important;
}

.btn-success.disabled, .btn-success[disabled], fieldset[disabled] .btn-success {
    border-color: $green-1 !important;
    background-color: $green-1 !important;
}

.btn-warning {
    border-color: $orange-1 !important;
    background-color: $orange-1 !important;
}

body.no-touch .btn-warning:hover,
.btn-warning.active, .btn-warning:active {
    border-color: $orange-2 !important;
    background-color: $orange-2 !important;
}

.btn-warning.disabled, .btn-warning[disabled], fieldset[disabled] .btn-warning {
    border-color: $orange-1 !important;
    background-color: $orange-1 !important;
}

.btn-danger {
    border-color: $red-1 !important;
    background-color: $red-1 !important;
}

body.no-touch .btn-danger:hover,
.btn-danger.active, .btn-danger:active {
    border-color: $red-2 !important;
    background-color: $red-2 !important;
}

.btn-danger.disabled, .btn-danger[disabled], fieldset[disabled] .btn-danger {
    border-color: $red-1 !important;
    background-color: $red-1 !important;
}

.ginger_container .ginger_btn {
	border-color: $cookie-notice-button-border-color !important;
	background-color: $cookie-notice-button-background-color !important;
}

body.no-touch .ginger_container .ginger_btn:hover,
.ginger_container .ginger_btn.active, .ginger_container .ginger_btn:active {
	border-color: $cookie-notice-button-border-color-active !important;
	background-color: $cookie-notice-button-background-color-active !important;
}

.ginger_container .ginger_btn.disabled, .ginger_container .ginger_btn[disabled], fieldset[disabled] .ginger_container .ginger_btn {
	border-color: $cookie-notice-button-border-color !important;
	background-color: $cookie-notice-button-background-color !important;
}



/*** Image Buttons ***/
.btn-image-left,
.btn-image-right,
.btn-image-center {
    background-repeat: no-repeat;
    background-size: auto 90%;
}

.btn-image-left {
    background-position: left center;
    padding-left: 40px;
    text-align: right;
}

.btn-image-right {
    background-position: right center;
    padding-right: 40px;
    text-align: left;
}

.btn-image-center {
    background-position: center center;
    text-align: center;
}

.btn-image-text-center {
    padding-right: 25px;
    padding-left: 25px;
    text-align: center;
}



/*** Cross nav button ***/
.btn-cross-nav {
    width: $offcanvas-button-size !important;
    height: $offcanvas-button-size !important;
    position: absolute;
	top: $navbar-padding;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 0 !important;
    border: none !important;

    text-indent: -9999px;

    border-radius: 50% !important;
}

.btn.btn-cross-nav {
	transition: $transition-time !important;
}

.btn-cross-nav {
    border-color: $offcanvas-button-color !important;
    background-color: $offcanvas-button-color !important;
}

body.no-touch .btn-cross-nav:hover,
.btn-cross-nav.active, .btn-cross-nav:active {
	border-color: $offcanvas-button-color-active !important;
	background-color: $offcanvas-button-color-active !important;
}

.btn-cross-nav.disabled, .btn-cross-nav[disabled], fieldset[disabled] .btn-cross-nav {
	border-color: $offcanvas-button-color !important;
	background-color: $offcanvas-button-color !important;
}

.btn-cross-nav span {
    display: block;
    position: absolute;
    top: round($offcanvas-button-size / 2.2);
    left: round($offcanvas-button-size / 5.0);
    right: round($offcanvas-button-size / 5.0);
    height: round($offcanvas-button-size / 18.0);
    background-color: $offcanvas-button-line-color !important;

	transition-duration: $transition-time;
	transition-delay: $transition-time, 0s;
}

.btn-cross-nav.active span {
	transition-delay: 0s, $transition-time;
}

.btn-cross-nav span::before,
.btn-cross-nav span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: round($offcanvas-button-size / 16.0);
    background-color: $offcanvas-button-line-color !important;
    content: "";

	transition-duration: $transition-time;
	transition-delay: $transition-time, 0s;
}

.btn-cross-nav span::before {
    top: round($offcanvas-button-size / 6.0) * -1;
	transition-property: top, transform;
}

.btn-cross-nav span::after {
    bottom: round($offcanvas-button-size / 6.0) * -1;
	transition-property: bottom, transform;
}

.btn-cross-nav.active span {
    background: none !important;
}

.btn-cross-nav.active span::before {
    top: 0;
	transform: rotate(45deg);
}

.btn-cross-nav.active span::after {
    bottom: 0;
	transform: rotate(-45deg);
}

.btn-cross-nav.active span::before,
.btn-cross-nav.active span::after {
	transition-delay: 0s, $transition-time;
}
